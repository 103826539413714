<template>
  <div class="section section__company">
    <div class="section__wrapper">
      <div class="title-with-icon title-with-icon_menu mb-5">
        <div class="title-with-icon__icon title-with-icon__icon_back"></div>
        <div class="title-with-icon__title"><a href="/sveden">Сведения об образовательной организации</a></div>
        <div class="title-with-icon__delimeter">/</div>
        <div class="title-with-icon__title">Стипендии и иные виды материальной поддержи</div>
      </div>

      <div class="notice notice_red mb-md-12">
        <div class="content_18">
          В Школе экспорта РЭЦ отсутствуют вакантные места для приема (перевода).
        </div>
      </div>

      <div class="my-5 d-block d-md-none">
        <a class="history-back" href="/sveden">Вернуться</a>
      </div>

      <div class="section__subsection_blue section__company-nav">
        <div class="section__subsection-wrapper">
          <company-navigation/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyNavigation from "../../components/CompanyNavigation";
export default {
  name: "InfoVacancy",
  components: {CompanyNavigation},
  mounted() {
    this.$parent.init()
  }
}
</script>